







































import Vue from "vue";
import { AUTH } from "../../routes/endpoints";

export default Vue.extend({
    data() {
        return {
            AUTH,
        };
    },
    methods: {
        goTo({ name }: { name: string }) {
            this.$router.push({ name });
        },
    },
});
